<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        label="วันที่เริ่ม"
        v-model="startDate"
        class="col-6">
      </sgv-input-date>

      <sgv-input-date
        label="วันที่สิ้นสุด"
        v-model="endDate"
        class="col-6">
      </sgv-input-date>
    </div>

    <button
      type="button"
      class="btn btn-warning"
      @click="syncData">
      Sync
    </button>

    <div v-if="isLoaded" class="mt-3">
      [ STATUS ]

      <div v-if="errorMessage">
        {{errorMessage}}
      </div>

      <ul class="list-unstyled" v-else>
        <li>Account ID: {{status.accountId}}</li>
        <li>Company ID: {{status.companyId}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  DETAIL_STATUS,
  SYNC_DATA
} from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isLoaded: false,
      errorMessage: '',
      status: {
        accountId: '',
        companyId: '',
      },
      message: '',
      startDate: null,
      endDate: null
    }
  },
  methods: {
    fetchData () {
      this.isLoaded = false
      this.$apollo.query({
        query: DETAIL_STATUS(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        const status = res.data.status
        Object.keys(this.status).forEach(key => {
          this.status[key] = status[key]
        })
      }).catch(() => {
        this.errorMessage = 'ไม่สามารถเชื่อมต่อได้'
      }).finally(() => {
        this.isLoaded = true
      })
    },
    syncData () {
      this.$apollo.mutate({
        mutation: SYNC_DATA(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          startDate: this.startDate,
          endDate: this.endDate
        }
      }).then(() => {
        this.$toasted.global.success('ส่งคำสั่งไปยังอุปกรณ์')
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
